import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Decimal from 'decimal.js';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      active: 0,
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      url: "",
      address: "",
      count: 0,
      app_root: api.base(),
      list: [],
      loading: false,
      finished: false,
      page: 1,
      pz2: {},
      pz4: {
        value: 0
      },
      pz5: {},
      num: 0,
      hui0: "",
      hui1: "",
      zfpass: "",
      list1: [],
      loading1: false,
      finished1: false,
      page1: 1,
      head_bg: ""
    };
  },
  computed: {
    result() {
      if (this.num) {
        let num1 = new Decimal(this.pz4.value);
        let num2 = new Decimal(this.num);
        return num1.times(num2).toFixed(4);
      }
      //this.price=num1.times(num2).toFixed(4)
    },
    result1() {
      if (this.num) {
        let num1 = new Decimal(this.pz5.value);
        let num2 = new Decimal(this.num);
        return num1.times(num2).toFixed(4);
      }
      //this.price=num1.times(num2).toFixed(4)
    }
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    this.head_bg = local.readInfo('head_bg');
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    onLoad() {
      this.loading = true;
      api.all('/api/index/buy_out_list', {
        page: this.page
      }, (err, data) => {
        this.loading = false;
        if (!err && data.code === 1) {
          this.page = this.page++;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list.push(data.data.list[i]);
          }
          if (this.list.length >= data.data.total) {
            this.finished = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onLoad1() {
      this.loading1 = true;
      api.all('/api/index/buy_in_list', {
        page: this.page1
      }, (err, data) => {
        this.loading1 = false;
        if (!err && data.code === 1) {
          this.page1 = this.page1++;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list1.push(data.data.list[i]);
          }
          if (this.list1.length >= data.data.total) {
            this.finished1 = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    get_imdex_data: function () {
      api.all('/api/index/shandui', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.pz2 = data.data.pz2;
          this.pz4 = data.data.pz4;
          this.pz5 = data.data.pz5;
          this.hui0 = "1HOC=" + this.pz4['value'] + "USDT";
          this.hui1 = "1HOC=" + this.pz5['value'] + "USDT";
          this.address = "http://" + window.location.hostname + "/#/login?code=" + this.user.code;
        } else {
          console.log(data);
        }
      });
    },
    buy_in: function () {
      api.all('/api/index/buy_in', {
        num: this.num,
        pass: this.zfpass
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          setTimeout(() => {
            this.get_imdex_data();
          }, 1500);
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    },
    buy_out: function () {
      api.all('/api/index/buy_out', {
        num: this.num,
        pass: this.zfpass
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          setTimeout(() => {
            this.get_imdex_data();
          }, 1500);
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    }
  }
};